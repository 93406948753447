import React, { Component } from "react";
import { Link } from "react-router-dom";
import useViewportSizes from "use-viewport-sizes";
import { Button, Typography, Container, Box, styled } from "@mui/material";
import { green } from "@mui/material/colors";
import "./../../assets/css/underConstruction.css";

function UnderConstruction() {
  const [vpWidth, vpHeight] = useViewportSizes();
  return (
    <div className="header">
      <div>
        <h1 style={{ fontFamily: "cursive" }}>Under Construction</h1>
        <h3 style={{ fontFamily: "-moz-initial" }}>
          We're making lots of improvements and will be back soon!
        </h3>

        {vpWidth <= 450 ? (
          <a style={{ textDecoration: "none" }} href="tel:8322964721">
            <Button style={{ fontFamily: "-moz-initial" }} variant="outlined">
              Call us instead
            </Button>
          </a>
        ) : (
          <a
            style={{ textDecoration: "none" }}
            href="mailto:sales@hbfencecompany.com?Subject=I%20want%20to%20get%20a%20free%20estimate"
          >
            <Button style={{ fontFamily: "-moz-initial" }} variant="outlined">
              Email Us
            </Button>
          </a>
        )}
      </div>
    </div>
  );
}

export default UnderConstruction;
