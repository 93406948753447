import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./../../../assets/css/cssSections/info.css";
export default function InfoSection() {
  return (
    <div className="info">
      <Box style={{ margin: styles.spacing }} sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <p>
              {" "}
              Tu hardware ya se está ejecutando No luche con el montaje de la
              plataforma y los mineros ruidosos y calientes en casa. ¡Ya tenemos
              el hardware de minería de bitcoins más rápido para usted!
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <p>
              Extraer criptomonedas alternativas ¡Puede extraer cualquier
              criptomoneda disponible en nuestro catálogo! Cambie su poder de
              minería sobre la marcha para todas las monedas utilizando nuestro
              sitio web de minería de bitcoins.
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <p>
              Obtenga su primera producción minera hoy Obtendrá salidas de
              minería periódicas en su billetera designada. ¡Pruebe nuestra
              plataforma de minería Bitcoin ahora!
            </p>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
const styles = {
  spacing: 10,
};
