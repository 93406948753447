import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./../../assets/css/aboutUs.css";
export default function AboutPage() {
  return (
    <div>
      <div className="header">
        <div>
          <h1>Una vision hecha realidad!</h1>
        </div>
      </div>
      <div className="identidad">
        <div>
          <h1>Quienes Somos?</h1>
          <Box style={{ margin: styles.spacing }} sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <p>
                  La historia de Genesis Mining comenzó a finales de 2013.
                  Nuestros fundadores se conocieron utilizando la misma
                  plataforma para comprar y vender Bitcoins. Estaban fascinados
                  con la tecnología y querían construir su propia granja, solo
                  para darse cuenta de que todos sus amigos también querían
                  participar.
                </p>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <p>
                  Se les ocurrió la idea de la minería como un servicio y
                  construyeron la primera granja minera en Europa del Este.
                  Desde nuestra fundación, hemos crecido enormemente y han
                  sucedido muchas cosas, pero una cosa permanece constante:
                  todos creemos firmemente en el futuro de las monedas digitales
                  y nos encanta ser parte de esta comunidad en crecimiento.
                </p>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}

const styles = {
  spacing: 10,
};
