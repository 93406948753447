import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useAuth } from "./../../AuthContext";

import { FormControl, TextField } from "@mui/material";
import "./../../assets/css/aboutUs.css";

function Registarte() {
  const firstName = useRef();
  const lastName = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const comfirmPasswordRef = useRef();
  const message = useRef();

  return (
    <div>
      <div className="header">
        <div>
          <h1>Registrate</h1>

          <FormControl>
            <Box style={styles.box} sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    ref={emailRef}
                    fullWidth
                    margin="normal"
                    placeholder="Email"
                    id="email"
                    type="email"
                    aria-describedby="my-helper-text"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    type="password"
                    ref={passwordRef}
                    fullWidth
                    margin="normal"
                    placeholder="Password"
                    id="password"
                    aria-describedby="my-helper-text"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    type="password"
                    ref={comfirmPasswordRef}
                    fullWidth
                    margin="normal"
                    placeholder="Comfirm Password"
                    id="ComfirmPassword"
                    aria-describedby="my-helper-text"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Button fullWidth variant="outlined">
                    Registrate
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Link to="/login">
                    <Button fullWidth variant="text">
                      Login
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default Registarte;

const styles = {
  box: {
    backgroundColor: "whitesmoke",
    padding: 10,
    borderRadius: 10,
    margin: 10,
  },
};
