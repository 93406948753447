import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import key from "./firebasekey";
//  Initialize Firebase

const app = firebase.initializeApp({
  apiKey: key.appKey,
  authDomain: key.authDomain,
  projectId: key.projectId,
  storageBucket: key.storageBucket,
  messagingSenderId: key.messagingSenderId,
  appId: key.appId,
});
export const auth = app.auth();

export default app;
