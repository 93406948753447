import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";

class LoginPage extends React.Component {
  state = {
    email: "",
    password: "",

    message: "",
  };

  onEmailChange = (value) => {
    this.setState({
      email: value.target.value,
      message: "",
    });
  };
  onPasswordChange = (value) => {
    this.setState({
      password: value.target.value,
      message: "",
    });
  };

  onSubmit = () => {
    if (this.state.email === "") {
      this.setState({
        message: "Please enter your Email",
      });
    } else if (this.state.password === "") {
      this.setState({
        message: "Please enter your Password",
      });
    } else {
      const userInfo = {
        email: this.state.email,
        password: this.state.password,
        lastVisitAt: Date.now(),
      };
      console.log(userInfo);

      this.setState({
        email: "",
        password: "",
        message: "",
      });
    }
  };
  render() {
    console.log("pr", this.props);
    return (
      <div>
        <div className="header">
          <div>
            <h1>Welcome Back</h1>
            <h1>{this.state.message}</h1>
            <FormControl fullWidth>
              <Box style={styles.box} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid fullWidth item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        value={this.state.email}
                        onChange={(v) => this.onEmailChange(v)}
                        fullWidth
                        margin="normal"
                        placeholder="Email "
                        id="my-input"
                        aria-describedby="my-helper-text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        value={this.state.password}
                        onChange={(v) => this.onPasswordChange(v)}
                        fullWidth
                        margin="dense"
                        placeholder="Password"
                        id="my-input"
                        aria-describedby="my-helper-text"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => this.onSubmit()}
                      >
                        Login
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Link to="/registrate">
                        <Button fullWidth variant="text">
                          Registrate
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;

const styles = {
  box: {
    backgroundColor: "whitesmoke",
    padding: 10,
    borderRadius: 10,
    margin: 10,
  },
};
