import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./assets/css/app.css";
import { AuthProvider } from "./AuthContext";

import ButtonAppBar from "./components/AppBar";
import Footer from "./components/Footer";
import AboutPage from "./Pages/About";
import DealsPage from "./Pages/Deals";

import HomePage from "./Pages/Home";
import LoginPage from "./Pages/Login";
import NotFound from "./Pages/NotFound";
import Registrate from "./Pages/Registrarte";
import UnderConstruction from "./Pages/UnderConstruction";
import Theme from "./Theme";

function App() {
  const underConstructionCheck = true;
  return (
    <AuthProvider>
      <Theme>
        <div className="App">
          {underConstructionCheck ? (
            <Router>
              <Routes>
                <Route exact path="/deals" element={<DealsPage />} />
                <Route exact path="/" element={<UnderConstruction />} />
                <Route exact path="*" element={<NotFound />} />
              </Routes>
            </Router>
          ) : (
            <Router>
              <ButtonAppBar />
              <Routes>
                <Route exact path="/conocenos" element={<AboutPage />} />
                <Route exact path="/registrate" element={<Registrate />} />
                <Route exact path="/login" element={<LoginPage />} />
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </Router>
          )}
        </div>
      </Theme>
    </AuthProvider>
  );
}

export default App;
