import { lightGreen, pink } from "@mui/material/colors";
const colorPink500 = lightGreen[400];
const colorPink300 = lightGreen[700];
const colorPink800 = lightGreen[200];
export const primary = {
  main: colorPink500,
  light: colorPink300,
  darker: colorPink800,
};
export const secondary = {
  main: "#",
  light: "#1aff1d",
  darker: "#e90859",
};
export const tertiary = {
  main: "#",
  light: "#1aff1d",
  darker: "#e90859",
};
