import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "./../../assets/css/noFound.css";
export default function NotFound(props) {
  console.log("props", props);
  return (
    <div className="no-found">
      <h1>Lo sentimos! no se encontro esta pagina!</h1>

      <Box style={{ margin: styles.spacing }} sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Button variant="link">
              {" "}
              <Link to="/"> Regresar</Link>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>
        </Grid>
      </Box>
    </div>
  );
}
const styles = {
  spacing: 10,
};
