import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import "./../../../assets/css/cssSections/creditCardPayment.css";
export default function CreditPaymentSection() {
  return (
    <div className="credit-card-payment">
      <Box style={{ margin: styles.spacing }} sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <h1>Pagos con tarjeta de crédito disponibles.</h1>

          <Grid item xs={12} sm={6} md={4}>
            <Button variant="outlined">Pagar</Button>{" "}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
const styles = {
  spacing: 10,
};
