import React, { Component } from "react";
import Button from "@mui/material/Button";
import "./../../assets/css/app.css";
import InfoSection from "../../components/Sections/Info";
import { Link } from "react-router-dom";
import { primary } from "./../../Theme/colors";
import CreditPaymentSection from "../../components/Sections/creditPayment";
class HomePage extends React.Component {
  render() {
    return (
      <div>
        <header className="App-header">
          <div>
            <h1 style={{ color: primary.main }}>
              ¡COMIENCE LA MINERÍA DE BITCOIN HOY!
            </h1>
            <h3>
              Es muy simple: sus equipos de minería estáran configurados y en
              funcionamiento. ¡Tan pronto como haya configurado su cuenta, puede
              comenzar a extraer sus primeras monedas utilizando nuestro
              servicio de minería Bitcoin Ahora!
            </h3>
            <div>
              <Link style={{ textDecoration: "none" }} to="/registrate">
                <Button
                  style={{ backgroundColor: primary.darker }}
                  variant="contained"
                >
                  Enpiesa a minar Ahora!
                </Button>
              </Link>
              <Button
                style={{ marginLeft: 10, textDecoration: "none" }}
                variant="outlined"
              >
                {" "}
                <Link
                  style={{ textDecoration: "none", color: primary.main }}
                  to="/Conocenos"
                >
                  {" "}
                  Aprende Más
                </Link>
              </Button>
            </div>
          </div>
        </header>
        <InfoSection />
        <CreditPaymentSection />
      </div>
    );
  }
}

export default HomePage;
