import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { primary } from "./colors";
const ThemeProviders = createTheme({
  palette: {
    primary: {
      main: primary.main,
    },
    secondary: {
      main: "#bd275d",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default function Theme(props) {
  return <ThemeProvider theme={ThemeProviders}>{props.children}</ThemeProvider>;
}
